.state-map .state path {
  pointer-events: all;
  cursor: pointer;
  transition: opacity 300ms ease;
}

.state text:hover + path {
  opacity: 0.5;
}

.state-map .state > g {
  cursor: pointer;
}
.state path {
  cursor: pointer;
}

.state text {
  font-size: 0.6rem;
  cursor: pointer;
  stroke: rgb(238 238 238);
  stroke-width: 0.6;
  font-weight: 100;
}

.state-map .state > g:hover path {
  opacity: 0.55;
}

.state-result {
  display: flex;
  margin-top: 5rem;
}

.state-result-card {
  padding: 1rem;
  border: 1px solid var(--divider-color);
  background-color: var(--white);
}

.state-result-card .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0;
}

.state-result-card .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.6rem;
  width: fit-content;
}

.state-result-card .circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.state-result-card .results-single-row {
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0;
  font-size: 0.85rem;
  color: var(--dark-grey-text);
}

.state-result-card .results-single-row:not(:last-child) {
  border-bottom: 1px solid var(--divider-color);
}

.election-links-wrapper {
  border: 1px solid var(--divider-color);
  border-top: none;
  background-color: var(--white);
}

/************** NEWS SECTION ************/

.home-news-section {
  margin-top: 2rem;
  background-color: var(--white);
  padding: 2rem;
  border-radius: 10px;
}

.home-news-section .single-news {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.home-news-section .single-news:not(:last-child) {
  margin-bottom: 1.2rem;
}

.home-news-section .single-news h1 {
  font-size: 1.2rem;
  font-weight: 600;
  transition: color 150ms ease;
  margin-bottom: 0.5rem;
}

.home-news-section .single-news h1:hover {
  color: var(--dark-primary-color);
}

/* .home-news-section .single-news .img-container {
  flex: 1 1 10rem;
} */

.home-news-section .single-news .desc {
  flex: 1 1 22rem;
}

.home-news-section .single-news img {
  width: 300px;
  height: 100%;
  /* object-fit: cover; */
  aspect-ratio: 16/9;
}

.home-news-section .single-news p {
  font-size: 0.85rem;
  color: var(--dark-grey-text);
  max-width: 72ch;
}
